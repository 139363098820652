
export default function(props_) {

    console.log('props_', props_);

    const props = Object.assign({
        foo: 'bar',
        baz: [],
    }, props_);

    return {
        init() {
            // eslint-disable-next-line no-console
            console.log('bar init', this.$el);
        }
    };
}
