import '../scss/app.scss'; // Vite requires css import in app.js
import '../css/app.css'; // Vite requires css import in app.js

import Alpine from 'alpinejs';
// import focus from '@alpinejs/focus'
// import intersect from '@alpinejs/intersect';

// Alpine.plugin(focus);
// Alpine.plugin(intersect);

import alpinemodules from './generated/alpine';
import vuemodules from './generated/vue';


Object.keys(alpinemodules).forEach(function(key) {
    Alpine.data(key, alpinemodules[key]);
});

Alpine.start();

Object.keys(vuemodules).forEach(function(key) {
    // eslint-disable-next-line no-unused-vars
    const module = vuemodules[key];
    // do something with module
});
