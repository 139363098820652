import { on } from 'klib/utils/dom/on';

export default function(props_) {
    const props = Object.assign({
    }, props_ || {});

    const $refs = this.$refs;
    let height;

    updateHeight();

    on(window, 'resize', () => {
        updateHeight();
    });

    function updateHeight() {
        height = $refs.header.getBoundingClientRect().height;

        // add the height of the header to a css variable 'foo' on the root html element
        document.documentElement.style.setProperty('--header-height', `${height}px`);
        // add the viewport height to a css variable 'vh' on the root html element
        document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    }


    return {
        init() {
            this.$watch('open', value => {
                document.body.style.overflow = value ? 'hidden' : '';
            });
        },
        open: false,
        active: true,
        height,
    };
}
