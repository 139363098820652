export default function(props_) {
    let mousePresent = false;
    document.body.addEventListener('mousemove', (event) => {
        mousePresent = true;
    });

    const props = Object.assign({
        selector: '',
        ease: 1,
        distance: 1,
        scrollDistance: 200,
        scrollLeftElement: null,
        scrollRightElement: null,
        limitToMediaQuery: '(min-width: 768px)',
        childSelector: '.scroll-child',
        contentSelector: '',
        gap: 0,
    }, props_);

    const easeFactor = 0.1 / props.ease;
    const distanceFactor = props.distance;
    const $container = this.$el;

    $container.scrollLeft = 0;

    let currentLocomotion = 0;

    function isDesktopAndMousePresent() {
        return window.matchMedia(props.limitToMediaQuery).matches && mousePresent;
    }

    function scroll(delta)  {
        currentLocomotion += ((delta * distanceFactor) * easeFactor);
    }

    $container.addEventListener('wheel', (event) => {
        if (!isDesktopAndMousePresent()) {
            return;
        }
        event.preventDefault();
        scroll(event.deltaY);
    });

    let dontAnimate = false;

    function animate() {
        if (!dontAnimate) {
            $container.scrollLeft += currentLocomotion;

            const scrollDirection = currentLocomotion > 0 ? 'right' : 'left';

            const isAtEnd = scrollDirection === 'right' && $container.scrollLeft + $container.clientWidth >= $container.scrollWidth;
            const isAtStart = scrollDirection === 'left' && $container.scrollLeft <= 0;

            currentLocomotion *= 0.9;

            if (isAtEnd || isAtStart || Math.abs(currentLocomotion) < 0.5) {
                currentLocomotion = 0;
            }
        }

        requestAnimationFrame(animate);
    }

    animate();
    let blockNext = false;

    function isAllright() {
        return $container.scrollLeft + $container.clientWidth >= $container.scrollWidth;
    }

    let interval = null;

    return {
        allright: false,
        $content: null,
        init() {
            this.$content = props.contentSelector ? document.querySelector(props.contentSelector) : null;
            interval = setInterval(() => {
                const allright = isAllright($container);
                if (allright !== this.allright) {
                    this.allright = allright;
                }
            }, 333);
        },
        destroy() {
            clearInterval(interval);
        },
        toNextElement() {
            if (blockNext) {
                return;
            }
            blockNext = true;
            setTimeout(() => {
                blockNext = false;
            }, 1000);
            const $scrollChildren = $container.querySelectorAll(props.childSelector);
            // left position of the container
            const containerLeft = $container.getBoundingClientRect().left;
            const contentWidth = this.$content ? this.$content.clientWidth : 0;
            const $nextChildren = [];

            Array.from($scrollChildren).find(($child) => {
                // get the scroll left posiiton of the child
                const childRect = $child.getBoundingClientRect();
                const childLeft = childRect.left;
                const scrollLeft = childLeft - containerLeft - contentWidth;
                const tolerance = 30;

                if ((scrollLeft - tolerance) > 0) {
                    $nextChildren.push({
                        $child,
                        scrollLeft,
                    });
                }
            });

            if (!$nextChildren.length) {
                return;
            }

            // sort the children by scroll left position
            $nextChildren.sort((a, b) => a.scrollLeft - b.scrollLeft);
            // get the first child
            const $nextChild = $nextChildren[0].$child;

            // $nextChild.classList.add('bg-black/10');
            // setTimeout(() => {
            //     $nextChild.classList.remove('bg-black/10');
            // }, 1000);

            if (contentWidth) {
                $container.scrollTo({
                    left: $nextChild.offsetLeft + contentWidth,
                    // left: 628,
                    behavior: 'smooth'
                });
            } else {
                $container.scrollBy({
                    left: $nextChild.getBoundingClientRect().left - containerLeft - props.gap,
                    behavior: 'smooth'
                });
            }

            dontAnimate = true;
            setTimeout(() => {
                dontAnimate = false;
            }, 1000);
        }
    };
}
