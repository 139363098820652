import { on } from 'klib/utils/dom/on';

export default function(props_) {

    const options = Object.assign({
        parent: '#main',
        headings: 'h1, h2, h3, h4, h5, h6',
    }, props_);

    const $main = document.querySelector(options.parent);

    if (!$main) return;


    // get all headlings in the main content
    const headings = $main.querySelectorAll(options.headings);
    // add ids to all headings
    headings.forEach((heading, index) => {
        const id = `heading-${index}`;
        heading.id = id;
    });

    const links = Array.from(headings).map(heading => {
        return {
            text: heading.innerText,
            href: `#${heading.id}`,
            $heading: heading,
            active: false,
        };
    });

    function isInViewport($heading) {
        // relative to $main
        const rect = $heading.getBoundingClientRect();
        const parentRect = $main.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= parentRect.height;
    }

    return {
        init() {
            // on scroll
            on(window, 'scroll', () => {
                this.updateLinks();
            });
            on($main, 'scroll', () => {
                this.updateLinks();
            });
            this.updateLinks();
        },
        updateLinks() {
            const activeLinks = [];

            this.links.forEach(link => {
                link.active = false
                if (isInViewport(link.$heading)) {
                    activeLinks.push(link);
                }
            });

            const first = activeLinks[0];
            if (first) {
                first.active = true;
            }
        },
        links,
        click(event) {
            event.preventDefault();
            const href = event.target.getAttribute('href');
            const target = document.querySelector(href);
            target.scrollIntoView({ behavior: 'smooth', });
        },
    };
}
