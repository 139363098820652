const on = function(el, eventnames, fn, opts_ = {}) {

    String(eventnames).split(' ').forEach(eventname => {
        el.addEventListener(eventname, fn);
    });
};

const off = function(el, eventnames, fn, opts_ = {}) {

    String(eventnames).split(' ').forEach(eventname => {
        el.removeEventListener(eventname, fn);
    });
};

export default on;

export {
    on,
    off,
};
