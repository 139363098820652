export default (el, className) => {
    const classNames = className.split(' ');
    classNames.forEach(className => {
        if (el.classList.contains(className)) {
            el.classList.remove(className);
        } else {
            el.classList.add(className);
        }
    });
};
