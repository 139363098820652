/**
 * components
 *
 * this file was generated by `php klib/scripts/generate-components.php`
 * changes will be overridden on each build or start of the dev server
 */

import dev from '../../../../klib/views/components/dev/dev.alpine.js';
import foo from '../../../../site/blocks/foo/foo.alpine.js';
import bar from '../../../../site/components/bar/bar.alpine.js';
import chapternavblock from '../../../../site/components/chapternavblock/chapternavblock.alpine.js';
import nav from '../../../../site/components/nav/nav.alpine.js';
import newsitem from '../../../../site/components/newsitem/newsitem.alpine.js';
import scrollx from '../../../../site/components/scrollx/scrollx.alpine.js';
import styleslib from '../../../../site/components/styleslib/styleslib.alpine.js';

export default {
    'dev': dev,
    'foo': foo,
    'bar': bar,
    'chapternavblock': chapternavblock,
    'nav': nav,
    'newsitem': newsitem,
    'scrollx': scrollx,
    'styleslib': styleslib,
};
