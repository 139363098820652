/**
 * components
 *
 * this file was generated by `php klib/scripts/generate-components.php`
 * changes will be overridden on each build or start of the dev server
 */



export default {

};
