
export default function(props_) {
    const props = Object.assign({
    }, props_ || {});

    return {
        active: false,
        // getMaxHeightStyle() {
        //     const $el = this.$refs.fonker;
        //     if ($el) {
        //         const height =  $el.getBoundingClientRect().height;
        //         return `max-height: ${height}px;`;
        //     }
        //     return '';
        // },
        toggle() {
            this.active = !this.active;
        },
    };
}
