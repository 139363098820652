export default function({ milliseconds = 200, count = 2 }) {
    let currentCount = 1;
    let lastClicked = new Date();

    return function() {
        const nowClicked = new Date();
        const diffSinceLastClick = nowClicked.getTime() - lastClicked.getTime();
        const allowCountup = diffSinceLastClick <= milliseconds;

        lastClicked = new Date();

        currentCount = allowCountup
            ? currentCount + 1
            : 1;

        if (currentCount === count) {
            currentCount = 1;
            return true;
        }

        return false;
    };
}
