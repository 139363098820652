/* eslint-disable no-console */
import multiclick from 'klib/utils/multiclick';
import on from 'klib/utils/dom/on';
import toggleClass from 'klib/utils/dom/toggleClass';

const store = window.localStorage;

export default function() {

    const debug = store.getItem('klib.debug') === 'true';

    const mc = multiclick({ count: 3 });
    const $body = document.body;

    if (debug) {
        toggleClass($body, 'debug pest');
    }

    on($body, 'click', function() {
        if (mc()) {
            toggleClass($body, 'debug pest');
            store.setItem('klib.debug', !debug);
        }
    });
}
